import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class RedirectInterceptor implements HttpInterceptor {
  constructor(private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          const redirectUrl = event.body?.redirect;
          const isRedirect = event.body?.is_redirected && event.body?.status === 301;

          if (isRedirect && redirectUrl && typeof redirectUrl === 'string') {
            this.router.navigateByUrl(redirectUrl); // Smooth, client-side navigation
          }
        }
      })
    );
  }
}
