import { AfterContentInit, ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ILanguage, ISocial } from './components/elementi-strutturali/header/header.interface';
import { DrupalService } from './services/drupal.service';
import { MessnegerService } from './services/messenger.service';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, AfterContentInit {

  currentLanguage: string = 'it';
  loginLink: string = 'login.html';
  navBrandLink: string = 'https://www.regione.veneto.it/';
  navBrandTitle: string = 'Regione del Veneto';
  brandTitle: string = 'Comune di Padova';
  brandTagline: string = 'Tagline del Comune di Padova';
  isLoaded: boolean = false;
  messenger = inject(MessnegerService)

  languages: ILanguage[] = [
    { label: 'IT', url: '#', langcode: 'it' },
    { label: 'EN', url: '#', langcode: 'en' },
  ];

  socialNetworks: ISocial[] = [
    { label: 'Facebook', url: 'https://www.facebook.com/Comune.Padova', icon: 'it-facebook', aria: 'Facebook' },
    { label: 'Instagram', url: 'https://www.instagram.com/comunepadova/', icon: 'it-instagram', aria: 'Instagram' },
    { label: 'Threds', url: 'https://www.threads.net/@comunepadova', icon: 'it-threads', aria: 'Threds' },
    { label: 'X', url: 'https://x.com/comunepadova', icon: 'it-twitter', aria: 'X' },
    { label: 'Linkedin', url: 'https://www.linkedin.com/company/comune-di-padova/', icon: 'it-linkedin', aria: 'Linkedin' },
    { label: 'Youtube', url: 'https://www.youtube.com/c/ComunediPadovaOfficial', icon: 'it-youtube', aria: 'Youtube' },
    { label: 'Podcast', url: '/podcasts', icon: 'it-file-audio', aria: 'Podcast' },
    { label: 'RSS', url: '/informazione/servizio-rss-di-padovanet', icon: 'it-rss', aria: 'RSS' },
  ];



  constructor(private translate: TranslateService, private cdr: ChangeDetectorRef) {
    const version = "0.0.20"
    console.log(`\n%cComune di Padova  ${version} 🚀`, 'color:#ea1923; background:#005959; font-size:1rem; padding:0.15rem 0.25rem; margin: 1rem auto; font-family: Rockwell; border: 2px solid #ffffff; border-radius: 4px;font-weight: bold; text-shadow: 1px 1px 1px #ea1923;');
  }


  ngOnInit(): void {
    this.currentLanguage = localStorage.getItem('currentLanguage') ?? 'it';
    this.translate.use(this.currentLanguage);
    // this.appendScript('/bootstrap-italia/dist/js/bootstrap-italia.bundle.min.js');
  }

  onLanguageChange(newLanguage: string) {
    this.currentLanguage = newLanguage;
    localStorage.setItem('currentLanguage', newLanguage);
    window.location.reload();
  }

  appendScript(url: string) {
    const script = document.createElement('script');
    script.src = url;
    script.type = 'text/javascript';
    script.async = true;
    document.head.appendChild(script);
    // document.body.appendChild(script);
  }

  appendStyle(name: string) {
    let style = document.createElement("link");
    style.rel = "stylesheet";
    style.type = "text/css";
    style.href = name;
    document.head.appendChild(style);
  }

  ngAfterContentInit() {
    window.onload = () => {
      // this.appendScript('assets/js/bootstrap-italia.bundle.min.js');
    }
  }

  ngAfterContentChecked() {
    this.messenger.currentMessage.subscribe((message) => {
      if (window.location.pathname == '/homepage' || window.location.pathname == '/') {
        if (message == 'last-loaded') {
          this.isLoaded = true;
        }
      } else {
        this.isLoaded = true;
      }
      this.cdr.detectChanges();
    });
  }

}
