import { Component, ElementRef, inject, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter, finalize, switchMap } from 'rxjs/operators';
import { RatingService } from 'src/app/services/rating.service';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss']
})
export class RatingComponent implements OnInit {
  @ViewChild('wrapper', { static: true }) wrapper: ElementRef | any;
  private ratingService = inject(RatingService);
  private activeStep: number = 0;
  private fieldset1: ElementRef | any;
  private fieldset2: ElementRef | any;
  private dataSubmitted = { stars: 0, radioSelected: '', radioSelectedValue: '', feedbackMessage: '', page: '', userAgent: '' }
  urlMap: Record<string, string> = {};
  feedbackError = false;
  isAlreadyInitialized = false;
  feedbackspinnerLoading$ = new BehaviorSubject<boolean>(false);
  private readonly storedData = JSON.parse(localStorage.getItem('feedback') ?? '[]');

  constructor(private readonly router: Router) { }


  ngOnInit(): void {


    if (window.location.pathname === '/' && !this.isAlreadyInitialized) {
      this.initRating(window.location.pathname);
    }
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: any) => {
      if (window.location.pathname !== '') {
        this.initRating(window.location.pathname);

      } else {
        this.initRating(event.urlAfterRedirects);
      }
    });

  }

  openNextStep() {
    const formWrapper = this.wrapper.nativeElement.querySelector('.form-rating');
    formWrapper.classList.remove('d-none');

    const inputRadio = this.wrapper.nativeElement.querySelector('[name="ratingA"]:checked');
    const currentFieldset = parseInt(inputRadio.value) > 3
      ? this.fieldset1
      : this.fieldset2;
    currentFieldset.classList.remove('d-none');

    const currentStepWrapper = this.wrapper.nativeElement.querySelector(`[data-step="${this.activeStep + 1}"]`);
    currentStepWrapper.classList.remove('d-none');
    currentStepWrapper.classList.add('active');

    const steps = this.wrapper.nativeElement.querySelectorAll('[data-step]');
    const currentPath = window.location.pathname === '/' ? 'homepage' : window.location.pathname;
    const activeRadio = this.wrapper.nativeElement.querySelector('[name="rating1"]:checked') ?? this.wrapper.nativeElement.querySelector('[name="rating2"]:checked');
    const feedbackMessage = this.wrapper.nativeElement.querySelector('[id="formGroupExampleInputWithHelp"]').value ?? '';
    this.activeStep = this.activeStep + 1;
    this.dataSubmitted = {
      stars: parseInt(inputRadio.value),
      radioSelected: activeRadio?.id,
      radioSelectedValue: activeRadio?.value,
      feedbackMessage: feedbackMessage,
      page: currentPath,
      userAgent: navigator.userAgent
    };

    const data = localStorage.getItem('feedback') ?? '[]';
    const feedbackArray = JSON.parse(data);

    if (this.activeStep === steps.length) {
      const cardRating = this.wrapper.nativeElement.querySelector('.cmp-rating__card-first');
      formWrapper.classList.add('d-none');
      cardRating.classList.add('d-none');
      const event = new Event('feedback-submit');

      // Aggiungi l'oggetto nel localStorage
      feedbackArray.push(this.dataSubmitted);
      localStorage.setItem('feedback', JSON.stringify(feedbackArray));
      console.log('Data submitted', this.dataSubmitted);

      this.feedbackspinnerLoading$.next(true);
      // Send rating to the backend
      this.ratingService.sendRating(this.dataSubmitted).pipe(
        finalize(() => this.feedbackspinnerLoading$.next(false))
      ).subscribe({
        next: (response) => {
          console.log('Rating inviato con successo', response);

          document.dispatchEvent(event);
          this.ngOnInit();
        },
        error: (error) => {
          console.error('Errore nell’invio del rating', error);


          // Rimuovi l'oggetto che è stato appena aggiunto in caso di errore
          const index = feedbackArray.indexOf(this.dataSubmitted);
          if (index > -1) {
            feedbackArray.splice(index, 1);
            localStorage.setItem('feedback', JSON.stringify(feedbackArray));
          }
          this.feedbackError = true;
        }
      });
    }
  }


  backToPrevious() {
    const formWrapper = this.wrapper.nativeElement.querySelector('.form-rating');
    formWrapper.classList.remove('d-none');

    this.activeStep = this.activeStep - 1;

    if (this.activeStep === 0) {
      formWrapper.classList.add('d-none');

      const cardRating = this.wrapper.nativeElement.querySelector('.cmp-rating__card-first');
      cardRating.classList.remove('d-none');

      this.resetFieldsets();

      this.wrapper.nativeElement.querySelector('[name="ratingA"]:checked').checked = false;
    } else {
      const previousWrapper = this.wrapper.nativeElement.querySelector(`[data-step="${this.activeStep + 1}"]`);
      previousWrapper.classList.add('d-none');
      previousWrapper.classList.remove('active');

      const currentWrapper = this.wrapper.nativeElement.querySelector(`[data-step="${this.activeStep}"]`);
      currentWrapper.classList.remove('d-none');
      currentWrapper.classList.add('active');
    }
  }

  makePristine() {
    this.feedbackError = false;
    this.activeStep = 0;
    this.feedbackspinnerLoading$.next(false);
    const radios = this.wrapper.nativeElement.querySelectorAll('.rating input');
    const btnNext = this.wrapper.nativeElement.querySelector('.btn-next');
    const btnBack = this.wrapper.nativeElement.querySelector('.btn-back');

    radios.forEach((radio: HTMLInputElement) => {
      radio.removeEventListener('change', this.handleRadioChange); // Usa la stessa funzione
    });
    if (btnNext) {
      btnNext.removeEventListener('click', this.handleNextButtonClick);
    }

    if (btnBack) {
      btnBack.removeEventListener('click', this.handleBackButtonClick);
    }
  }
  handleRadioChange = (event: Event) => {
    const radio = event.target as HTMLInputElement;
    this.resetBlock();
    if (radio.checked) {
      this.openNextStep();
    }
  };
  // Funzioni che gestiscono gli eventi per i pulsanti
  handleNextButtonClick = () => {
    this.closePreviousStep();
    this.openNextStep();
  };

  handleBackButtonClick = () => {
    this.closePreviousStep();
    this.backToPrevious();
  };
  resetBlock() {
    this.closePreviousStep();

    const formWrapper = this.wrapper.nativeElement.querySelector('.form-rating');
    formWrapper.classList.add('d-none');

    this.resetFieldsets();

    this.activeStep = 0;
  }

  resetFieldsets() {
    this.fieldset1.classList.add('d-none');
    this.fieldset2.classList.add('d-none');
  }

  closePreviousStep() {
    const stepWrapper = this.wrapper.nativeElement.querySelector('[data-step].active');
    if (stepWrapper) {
      stepWrapper.classList.add('d-none');
      stepWrapper.classList.remove('active');
    }
  }

  initRating(url: string = '') {
    this.makePristine();

    const currentData = JSON.parse(localStorage.getItem('feedback') ?? '[]');
    let urlMatch = false;
    let stars: number = 0;
    if (url === '/') url = 'homepage';
    currentData.forEach((element: any) => {
      if (element.page === url) {
        urlMatch = true;
        stars = element.stars;
      }
    });

    if (urlMatch) {
      const formRating = this.wrapper.nativeElement.querySelector('.form-rating')
      formRating.classList.add('d-none');
      const cardRating = this.wrapper.nativeElement.querySelector('.cmp-rating__card-first');
      cardRating.classList.add('d-none');
      const cardRatingSecond = this.wrapper.nativeElement.querySelector('.cmp-rating__card-second');
      cardRatingSecond.classList.remove('d-none');
      const radios = this.wrapper.nativeElement.querySelectorAll('.rating input');

      radios.forEach((radio: HTMLInputElement) => {
        radio.checked = parseInt(radio.value) === stars;
        radio.disabled = true;
      });
      const rating = this.wrapper.nativeElement.querySelector('.rating');
      rating.classList.add('d-none');
      cardRatingSecond.appendChild(rating);
      cardRatingSecond.querySelector('.rating').classList.remove('d-none');
    } else {

      const cardRatingSecond = this.wrapper.nativeElement.querySelector('.cmp-rating__card-second');
      cardRatingSecond.classList.add('d-none');
      const cardRating = this.wrapper.nativeElement.querySelector('.cmp-rating__card-first');
      cardRating.classList.remove('d-none');
      const rating = this.wrapper.nativeElement.querySelector('.rating');
      cardRating.appendChild(rating);
      cardRating.querySelector('.rating').classList.remove('d-none');

      this.fieldset1 = this.wrapper.nativeElement.querySelector('.fieldset-rating-one');
      this.fieldset2 = this.wrapper.nativeElement.querySelector('.fieldset-rating-two');
      const radios = this.wrapper.nativeElement.querySelectorAll('.rating input');
      const btnNext = this.wrapper.nativeElement.querySelector('.btn-next');
      const btnBack = this.wrapper.nativeElement.querySelector('.btn-back');
      radios.forEach((radio: HTMLInputElement) => {
        radio.disabled = false;
        radio.checked = false;
        radio.addEventListener('change', this.handleRadioChange);
      });

      if (btnNext) {
        btnNext.addEventListener('click', this.handleNextButtonClick);
      }

      if (btnBack) {
        btnBack.addEventListener('click', this.handleBackButtonClick);
      }
    }
  }

}
