// src/app/app-routing.module.ts

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './layouts/page-not-found/page-not-found.component';
const routes: Routes = [
  {
    path: ':path',
    loadChildren: () => import('./layouts/layouts.module').then(m => m.LayoutsModule)
  },
  // {
  //   path: '',
  //   redirectTo: '/',
  //   pathMatch: 'full',  // This redirects the base route to the dynamic layout for now. Adjust as required.
  // },
  // {
  //   path: '404',
  //   component: PageNotFoundComponent
  // },
  {
    path: '**', // This is a wildcard route to catch any undefined routes.
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
