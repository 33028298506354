import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
export interface RatingData {
  stars: number;
  radioSelected: string;
  radioSelectedValue: string;
  feedbackMessage: string;
  page: string;
  userAgent: string;
}
@Injectable({
  providedIn: 'root'
})
export class RatingService {
  private http = inject(HttpClient);
  private API_URL = environment.config.backendUrl;

  sendRating(data: RatingData): Observable<any> {
    return this.http.post<any>(`${this.API_URL}/rating`, data);
  }
}
