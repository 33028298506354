import { CommonModule, DatePipe, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpBackend } from '@angular/common/http';
import localeIt from '@angular/common/locales/it';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterModule } from './components/elementi-strutturali/footer/footer.module';
import { HeaderModule } from './components/elementi-strutturali/header/header.module';
import { RatingModule } from './components/elementi-strutturali/rating/rating.module';
import { SearchModule } from './components/elementi-strutturali/search/search.module';
import { LayoutsModule } from './layouts/layouts.module';
import { DrupalService } from './services/drupal.service';
import { CookieBannerComponent } from './shared/components/cookie-banner/cookie-banner.component';
import { RedirectInterceptor } from './utils.ts/redirect.interceptor';

registerLocaleData(localeIt);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    CookieBannerComponent,
    CommonModule,
    BrowserModule,
    HeaderModule,
    FooterModule,
    LayoutsModule,
    RatingModule,
    SearchModule,
    AppRoutingModule,


    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpBackend) => new MultiTranslateHttpLoader(http, [
          './assets/i18n/', // Your i18n location
        ]),
        deps: [HttpBackend],
      },
      // defaultLanguage: 'it'
    }),
    // ServiceWorkerModule.register('ngsw-worker.js', {
    //   enabled: true,
    // }),
  ],
  providers: [DrupalService, { provide: LOCALE_ID, useValue: 'it-IT' }, DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RedirectInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
